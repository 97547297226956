<template>
  <vue-draggable-resizable 
    :draggable="false"
    class="left-0 bottom-0 border-t border-indigo-100 tracingcontainer"
    ref="tracingcontainer" 
    style="background: rgb(255 255 255 / 60%); backdrop-filter: blur(10px); z-index: 10; width: 100%; position: fixed; transform: none; padding-bottom: 50px;"
    :min-height="224"
    :handles="['tm']"
  >
    <ul class="flex grab overflow-x-scroll no-scrollbar h-full" v-dragscroll.x="true">
      <li 
        v-if="readyItems.length" 
        class="w-56 border-l border-dotted relative tracing-line-first bg-white" 
        style="min-width: 120px;"
      >
        <span class="tracing-views-max absolute font-mono text-xs base-font-gray-lighter-220">{{ formatNumberToSmallView(maxViews) }}</span>
        <span class="tracing-views-half absolute font-mono text-xs base-font-gray-lighter-220">{{ formatNumberToSmallView(Math.round(maxViews / 2)) }}</span>
      </li>
      <li 
        v-for="item in readyItems" 
        :key="item.month" 
        class=" w-56 border-l border-dotted relative flex flex-col justify-end tracing-line" 
        style="min-width: 120px;"
      >
        <span 
          class="font-sans text-xs base-font-gray-lighter-100 uppercase absolute top-2 left-2"
        >
          {{ $moment(item.date).format('MMM ‘YY') }}
        </span>

        
        <div 
          v-if="item.list.length"
          :style="{ height: `calc(100% - 40px)` }"
          class="flex flex-col justify-end relative"
        >
          <div class="w-3 h-3 rounded-full purple-bg-100 translate-x-50 mb-1"></div>
          <div  
            v-for="(video, index) in item.list"
            :key="`video-${index}`"
            :style="{
              height: `${Math.round(( video.views / maxViews) * 100)}%`,
              width: '3px'
            }"
            class="translate-x-50 relative pb-1"
          > 
            <!-- <span class="absolute left-4 top-0">{{ video.views }}</span> -->
            <div class="h-full purple-bg-110"></div>
          </div> 
        </div> 
        <div 
          v-if="item.list.length"
          class="absolute h-full w-1/2 overflow-auto bottom-0 left-3 flex flex-wrap items-end flex-col-reverse"
        >
          <div  
            v-for="(video, index) in item.list"
            :key="`thumb-${index}`"
            class="flex items-center z-10 w-full h-6 mb-2"
            :class="[ index > 0 ? 'mt-1' : '' ]"
          > 
            <img :src="`https://i3.ytimg.com/vi/${video.videoId}/default.jpg`" class="w-10 mt-2 mb-4 h-full rounded-lg bg-cover">
            <span class="font-mono text-xs-max text-black ml-3">{{ formatNumberToSmallView(video.views) }}</span>
          </div> 
        </div>
      </li>
    </ul>
  </vue-draggable-resizable>
</template>
  
<script>
import { mapGetters } from 'vuex';
import { dragscroll } from 'vue-dragscroll'
import { formatNumberToSmallView } from '@/helpers/formats'

export default {
  name: 'NarrativeTracing',
  directives: {
    'dragscroll': dragscroll
  },
  data: () => {
    return {
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      maxViews: 0,
      readyItems: [],
      formatNumberToSmallView: formatNumberToSmallView
    }
  },
  computed: {
    ...mapGetters({
      narrativeTracingSaved: 'search/narrativeTracingSaved'
    }),
  },
  methods: {
    back_fill_months (data) {
      let newData = []
      const first_index = this.months.findIndex(elem => elem == data[0].month)
      const last_index = this.months.findIndex(elem => elem == data[ data.length - 1 ].month)
      const new_months_list = this.months.slice(first_index, last_index + 1)

      let presentItemIndex = null
      new_months_list.forEach((elem) => {
        presentItemIndex = data.findIndex(present => present.month == elem)
        if(presentItemIndex == -1) {
          newData.push({
            month: elem,
            date: this.$moment().startOf('year').add(this.months.findIndex(item => item == elem), 'M'),
            list: []
          })
        }else{
          newData.push(data[presentItemIndex])
        }
      })
      return newData
    },
    sortedItems(val){
      if(val.length){
        let itemsScope = []
        let monthsList = val.map(elem => this.$moment.unix(elem.date).format('MMMM'))
        monthsList = [...new Set(monthsList)]
        monthsList.forEach(elem => itemsScope.push({
          month: elem,
          date: null,
          list: []
        }))

        let monthIndex = null
        val.forEach(elem => {
          monthIndex = itemsScope.findIndex(itemIndex => itemIndex.month == this.$moment.unix(elem.date).format('MMMM'))
          if(!itemsScope[monthIndex].date) itemsScope[monthIndex].date = this.$moment.unix(elem.date).startOf('month')
          itemsScope[monthIndex].list.push(elem)
        })

        // itemsScope.push({
        //   month: 'September',
        //   date: this.$moment().startOf('year').add(8, 'M'),
        //   list: []
        // })
        
        itemsScope = itemsScope.sort(( b, a ) => this.$moment(b.date).unix() - this.$moment(a.date).unix())

        itemsScope = this.back_fill_months(itemsScope)

        let viewsArray = itemsScope.map( elem => {
          return elem.list.map(view => view.views ).reduce((partialSum, a) => partialSum + a, 0)
        })
        viewsArray = viewsArray.sort(( a, b ) => b - a )
        this.maxViews = viewsArray[0]
        this.readyItems = JSON.parse(JSON.stringify(itemsScope))
      }
      else this.readyItems = []
    }
  },
  watch: {
    narrativeTracingSaved: {
      handler(val){
        this.sortedItems(val)
      },
      deep: true
    }
  }
}
</script>
  
<style scoped lang="scss">
.grab {
  cursor : -webkit-grab;
  cursor : -moz-grab;
  cursor : -o-grab;
  cursor : grab;
}
.tracing-line{
  &:before,
  &:after{
    content: '';
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #CDCDCD;
  }
  &:before{
    top: 45px;
  }
  &:after{
    top: calc(50% + 24px);
  }
}
.tracing-line-first{
  &:before,
  &:after{
    content: '';
    position: absolute;
    right: 0;
    height: 1px;
    width: 50%;
    background-color: #CDCDCD;
  }
  &:before{
    top: 45px;
  }
  &:after{
    top: calc(50% + 24px);
  }
}
.tracing-views-max,
.tracing-views-half{
  right: calc(50% + 10px);
}
.tracing-views-max{
  top: 38px;
}
.tracing-views-half{
  top: calc(50% + 16px);
}
.tracing-thumbs{
  max-height: 80%;
}
</style>